import { useState, useEffect, useRef, useMemo } from "react"
import GenericToolbar from 'pages/settings/Toolbars/GenericToolbar';
import { EventLogsPopup } from './EventLogsPopup';
import { useAuth } from 'contexts/auth'
import AccessPrivileges from 'app-privileges'
import OrganizationContextOdataGrid from './OrganizationContextOdataGrid';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getAllButtons } from './allButtons';
import useDataGridFilter from 'utils/useDataGridFilter'
import AttachmentsPopup from "./AttachmentsPopup";
import { InvoicePreview } from "pages/Invoices/Popups/InvoicePreview";
import { TagsEditor } from "pages/Invoices/Popups/TagsEditor";
import { useNavigate } from 'react-router';
import { CreateBatchPopup } from "pages/Invoices/Popups/CreateBatchPopup";
import { AcceptanceUsersPopup } from 'pages/Invoices/Popups/AcceptanceUsersPopup'
import { ErrorRaportPreview } from 'pages/Invoices/Popups/ErrorRaport'
import { EventLogsTypes } from 'pages/Invoices/EventLogsPopup'
import { EditorInPopup } from 'pages/Invoices/Editor/EditorInPopup'
import ReceptionSyncPopup from 'pages/Invoices/Popups/ReceptionSyncPopup';
import { GetExportRepoAction } from 'pages/Export/action.ts'
import ExportPopup from 'components/export-popup/ExportPopup';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "../../contexts/UserSettingsProvider";
import { XfXApi } from 'api/XfXApi';
import { FilterUsageTypes } from "./GenericOdataGrid";
import { EditorType } from 'pages/Invoices/Editor/model'
import DeletionPopup from 'components/popup/DeletionPopup';
import ImporterPopup from "pages/Invoices/Popups/ImporterPopup";
import MigrationPopup from "pages/Invoices/Popups/MigrationPopup.tsx";
import ImporterFromTokensPopup from "pages/Invoices/Popups/ImporterFromTokensPopup";
import { useOrganizations } from "contexts/OrganizationContext";

const showWipFeatures = localStorage.getItem("EnableWipFeatures") === "true"

const OneInvoiceGrid = ({ id, endpoint, breadCrumbs, buttonsIds, getColumns, privileges, custom }) => {
  const { t } = useTranslation()
  const { hasPrivilege, hasAnyPrivilege, userId, isAdmin, privileges: userPriviliges } = useAuth()
  const navigate = useNavigate();

  const { getOrganizationContextSettings, getEmptySorting } = useUserSettingsContext() // ?
  const organizationId = getOrganizationContextSettings()?.NIP
  const { organizations, isOrganizationDisabled, getOrganizationFullNameByNip } = useOrganizations()
  const [currentOrganization, setCurrentOrganization] = useState()
  const currentOrganizationDisabled = useMemo(() => isOrganizationDisabled(organizationId), [organizationId, isOrganizationDisabled])

  useEffect(() => {
    const org = organizations?.find(x => x.nip === organizationId)
    setCurrentOrganization(org)
  }, [organizationId, organizations])

  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter, activeQueryArguments, deleteActiveQueryArgument } = useDataGridFilter({ initialFilter, queryArguments: ["BatchId", "SessionId", "SyncProcessId"] })
  const filterButtons = activeQueryArguments.map(x => { return { text: x, onClick: () => deleteActiveQueryArgument(x) } })

  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItems, setSelectedItems] = useState(null)
  const [eventLogsVisible, setEventLogsVisible] = useState(false)
  const [attachmentsVisible, setAttachmentsVisible] = useState(false)
  const [createBatchPopupVisible, setCreateBatchPopupVisible] = useState(false)
  const [reloadKey, setReloadKey] = useState(0)

  const [selectedFilesItemKeys, setSelectedFilesItemKeys] = useState([])

  const [lastUsedQueryParms, setLastUsedQueryParms] = useState([])
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [receptionSyncVisible, setReceptionSyncVisible] = useState(false)
  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)
  const [usersManagement, setUsersManagement] = useState(false)
  const [columns, setColumns] = useState([])
  const [defaultSort, setDefaultSort] = useState(null)
  const dataGridRef = useRef(null)
  const reloadGrid = () => {
    setReloadKey(oldVal => oldVal + 1)
  }

  const invoicePreview = InvoicePreview(setBlockAutoRefresh)
  const errorRaport = ErrorRaportPreview(setBlockAutoRefresh)
  const editorPopup = EditorInPopup(() => reloadGrid(), { isAdmin, privileges: userPriviliges }, () => setBlockAutoRefresh())
  const dispatchType = custom?.subjectType;
  const tagsEditor = TagsEditor(reloadGrid, setBlockAutoRefresh)
  const isDraft = custom?.draft;

  const [invKindDataSource, setInvKindDataSource] = useState([])
  const [subject3RoleDataSource, setSubject3RoleDataSource] = useState([])
  const [currencyCodesDataSource, setCurrencyCodesDataSource] = useState([])
  const [processingStatusDataSource, setProcessingStatusDataSource] = useState([])
  const [saleDateTagsDataSource, setSaleDateTagsDataSource] = useState([])
  const [ansiCodesDataSource, setAnsiCodesDataSource] = useState([])

  const [dataSourceOptions, setDataSourceOptions] = useState()
  const [externalTrackingId, setExternalTrackingId] = useState(null)

  const [isExportPopupVisible, setIsExportPopupVisible] = useState(false)

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftIdDelete(selectedItem.Id, XfXApi.GetTenantId(), organizationId);
      reloadGrid()
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const deleteDraft = async () => {
    setDeletionPopupContent(t("#_DeletionPopup"))
    deletionPopup.show()
  }


  useEffect(() => {
    const es = getEmptySorting(id)
    const emptySortingExists = es !== undefined && es !== null

    if (emptySortingExists && es)
      setDefaultSort(null)
    else
      setDefaultSort({ selector: "DateReceivedUtc", desc: true })
  }, [])

  useEffect(() => {
    setUsersManagement(hasAnyPrivilege(AccessPrivileges.Users))
  }, [hasAnyPrivilege])

  useEffect(() => {
    async function getDictionariesAndSetColumns() {
      const invoiceKindsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceKindsGet(XfXApi.GetTenantId())
      const subject3RolesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionarySubject3RolesGet(XfXApi.GetTenantId())
      const currencyCodesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryCurrencycodesGet(XfXApi.GetTenantId())
      const processingstatusesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryProcessingstatusesGet(XfXApi.GetTenantId())
      const saleDateTagsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionarySaleDateTagsGet(XfXApi.GetTenantId())
      const ansiCodesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryAnsiCodesGet(XfXApi.GetTenantId())

      const invKindData = (await invoiceKindsPromise).data
      const subject3RoleData = (await subject3RolesPromise).data
      const currencyCodesData = (await currencyCodesPromise).data
      const processingStatusData = (await processingstatusesPromise).data
      const saleDateTagsData = (await saleDateTagsPromise).data
      const ansiCodesData = (await ansiCodesPromise).data

      setInvKindDataSource(invKindData)
      setSubject3RoleDataSource(subject3RoleData)
      setCurrencyCodesDataSource(currencyCodesData)
      setProcessingStatusDataSource(processingStatusData)
      setSaleDateTagsDataSource(saleDateTagsData)
      setAnsiCodesDataSource(ansiCodesData)

      const col = getColumns({
        organizationId, setSelectedItem, reloadGrid: () => {
          dataGridRef?.current?.instance?.refresh()
        },
        userId,
        t,
        invKindDataSource: invKindData,
        subject3RoleDataSource: subject3RoleData,
        currencyCodesDataSource: currencyCodesData,
        processingStatusDataSource: processingStatusData,
        saleDateTagsDataSource: saleDateTagsData,
        getOrganizationFullNameByNip: getOrganizationFullNameByNip,
        currentOrganizationDisabled: currentOrganizationDisabled
      })
      setColumns(col);
    }

    if (invKindDataSource?.length !== 0 && subject3RoleDataSource?.length !== 0 && currencyCodesDataSource?.length !== 0 &&
      processingStatusDataSource?.length !== 0 && saleDateTagsDataSource?.length !== 0) {
      const col = getColumns({
        organizationId, setSelectedItem, reloadGrid: () => {
          dataGridRef?.current?.instance?.refresh()
        },
        userId,
        t,
        invKindDataSource: invKindDataSource,
        subject3RoleDataSource: subject3RoleDataSource,
        currencyCodesDataSource: currencyCodesDataSource,
        processingStatusDataSource: processingStatusDataSource,
        saleDateTagsDataSource: saleDateTagsDataSource,
        getOrganizationFullNameByNip: getOrganizationFullNameByNip,
        currentOrganizationDisabled: currentOrganizationDisabled
      })
      setColumns(col);
    }
    else {
      getDictionariesAndSetColumns()
    }
  }, [currencyCodesDataSource, currentOrganizationDisabled, getColumns, invKindDataSource, organizationId, organizations, processingStatusDataSource, saleDateTagsDataSource, subject3RoleDataSource, t, userId])


  const acceptanceUsersPopup = AcceptanceUsersPopup({
    usersManagement: usersManagement,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const exportPopup = ExportPopup({
    t, exportAction: GetExportRepoAction({ organizationId, dataGridRef, lastUsedQueryParms, gridId: id }),
    isVisible: isExportPopupVisible, setIsVisible: setIsExportPopupVisible, setBlockAutoRefresh
  })

  const createBatchPopup = CreateBatchPopup({
    organizationId: organizationId, selectedDocumentsIds: selectedItems?.map(x => x.Id?._value ? x.Id?._value : x.Id),
    batchDispatchType: dispatchType, externalTrackingId: externalTrackingId, setExternalTrackingId,
    afterSave: () => dataGridRef?.current?.instance?.refresh(), t: t, isVisible: createBatchPopupVisible,
    setIsVisible: setCreateBatchPopupVisible, setBlockAutoRefresh: setBlockAutoRefresh
  })

  const availableButtons = getAllButtons({
    deleteDraft: () => deleteDraft(),
    organizationId,
    organization: currentOrganization,
    selectedItem: selectedItem,
    selectedItems,
    hasPrivilege: hasPrivilege,
    setEventLogsVisible,
    setImporterVisible: () => importerPopup.show(),
    setEditorVisible: async (type, editorTokensSource) => {
      await editorPopup.show(type, type === EditorType.Vat ? null : selectedItem.Id, editorTokensSource)
    },
    setAttachmentsVisible,
    setInvoicePreviewUrl: invoicePreview.showInvoicePreview,
    showErrorRaport: () => errorRaport.showErrors(selectedItem.BusinessError),
    downloadErrorRaport: () => errorRaport.downloadReport(selectedItem.BusinessError),
    showCreateBatchPopup: () => {
      createBatchPopup.show()
    },
    showTagsPopup: (x) => tagsEditor.show(x),
    showExportPopup: () => {
      exportPopup.show()
    },
    hasAnyPrivilege: hasAnyPrivilege, navigate,
    acceptanceUsersPopup, usersManagement,
    reloadGrid: reloadGrid,
    dataGridRef,
    setReceptionSyncVisible,
    t,
    lastUsedQueryParms,
    gridId: id,
    setImporterFromTokensVisible: () => importerFromTokensPopup.show(),
    migrateInvoices: () => {
      migrationPopup.show(selectedItems.map(x => x.Id))
    },
    getOrganizationFullNameByNip: getOrganizationFullNameByNip
  })

  const buttons = [
    {
      id: "select-columns", icon: 'grid', text: t('button-column-management'),
      onClick: async () => { dataGridRef.current.instance.showColumnChooser() },
      requireMultiSelection: true
    },

    ...buttonsIds.map(id => availableButtons.find(b => b.id === id) ?? { text: id + '?' })
      .filter(x => x !== undefined)
      .filter(x => showWipFeatures || x.wip !== true)]

  const importerPopup = ImporterPopup({
    organizationId,
    dispatchType,
    setSelectedFilesItemKeys,
    selectedFilesItemKeys,
    isDraft,
    t,
    afterSave: () => reloadGrid(),
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const importerFromTokensPopup = ImporterFromTokensPopup({
    organizationId,
    dispatchType,
    setSelectedFilesItemKeys,
    selectedFilesItemKeys,
    t,
    afterSave: () => reloadGrid(),
    setBlockAutoRefresh: setBlockAutoRefresh,
    ansiCodesDataSource: ansiCodesDataSource
  })

  const migrationPopup = MigrationPopup({
    gridId: id,
    organizationId: organizationId,
    setBlockAutoRefresh: setBlockAutoRefresh,
    onSaved: () => reloadGrid()
  })

  useEffect(() => {
    const eventHandler = (e) => {
      if (e.detail.message.message.includes("migracji") || e.detail.message.message.includes("migration")) {
        dataGridRef?.current?.instance?.clearSelection()
        dataSourceOptions.reload()
      }
    }

    document.addEventListener("XfxNotification", eventHandler)
    return () => {
      document.removeEventListener("XfxNotification", eventHandler)
    }
  })

  return (
    <>
      {invoicePreview.popup}
      {exportPopup.popup}
      {migrationPopup.popup}
      {attachmentsVisible &&
        <AttachmentsPopup
          invoice={selectedItem}
          invoiceId={selectedItem?.Id}
          setAttachmentsVisible={setAttachmentsVisible}
          attachmentsVisible={attachmentsVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </AttachmentsPopup>
      }
      {eventLogsVisible &&
        <EventLogsPopup
          type={selectedItem?.IsDispatch ? EventLogsTypes.Dispatch : EventLogsTypes.Reception}
          guid={selectedItem?.Id}
          setEventLogsVisible={setEventLogsVisible}
          eventLogsVisible={eventLogsVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </EventLogsPopup>
      }
      {errorRaport.popup}
      {createBatchPopup.popup}
      {acceptanceUsersPopup.popup}
      {receptionSyncVisible &&
        <ReceptionSyncPopup
          isVisible={receptionSyncVisible}
          setIsVisible={setReceptionSyncVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}
        />
      }
      {tagsEditor.popup}
      {editorPopup.popup}
      {importerPopup.popup}
      {importerFromTokensPopup.popup}
      {deletionPopup.popup}
      <BreadCrumbs breadCrumbs={breadCrumbs.map(t)}></BreadCrumbs>
      <GenericToolbar
        className="xfx-toolbar-bigbuttons"
        buttons={buttons}>
      </GenericToolbar>
      <div id="dataGrid-processedInvoices" className="dataGrid">
        {OrganizationContextOdataGrid({
          id: id,
          ref: dataGridRef,
          data: columns,
          endpoint, setSelectedItem,
          setSelectedItems,
          defaultFilter: dataGridFilter,
          defaultSort: defaultSort,
          filterButtons,
          updateDataGridFilter,
          multiSelectionMode: buttons.some(x => x.requireMultiSelection && x.requireMultiSelection === true),
          setLastUsedQueryParms,
          setDefaultSort,
          useFilterBuilder: true,
          gridKey: reloadKey,
          dataSourceOptions: dataSourceOptions,
          setDataSourceOptions,
          filterUsageType: FilterUsageTypes.ODataSourceOptions,
          blockAutoRefresh: blockAutoRefresh,
          setBlockAutoRefresh: setBlockAutoRefresh
        })}
      </div>
    </>
  );
}

export default OneInvoiceGrid
