import axios from "axios"
import { AADGetTokenDataForce } from 'contexts/aad'
import {
  InfoApi,
  Info2Api,
  LicenseApi,
  OrganizationApi,
  UserApi,
  ContractorApi,
  PipelineApi,
  AccountingSystemApi,
  ModuleApi,
  RoleApi,
  KsefRoleApi,
  KsefAuthApi,
  KsefCredentialsApi,
  ExitPathApi,
  DocumentTemplateApi,
  OrganizationSyncSettingApi,
  DictionaryApi,
  UserSettingsApi,
  SettingsApi,
  NotificationConfigurationApi,
  SecurityLogApi,
  NumberingSeriesApi,
  ReportApi,
  ReportODataApi,
  SelfInvoicingGrantedApi,
  SelfInvoicingGrantedOdataApi,
  UserODataApi,
  OrganizationODataApi,
  ConfigImportApi,
  ConfigExportApi,
  AccountingSystemODataApi,
} from "./openapi"

import {
  AttachmentsApi,
  BatchApi,
  InvoicesApi,
  TagsApi,
  ExportApi,
  JobsApi,
  DictionaryApi as DictionaryInvoiceApi,
  EditorApi,
  ConfigS3Api,
  InvoiceDraftApi,
  MigrationApi
  
} from "./openapi-invoice"

export const ksefixEndpointUrl = window.XFX_WEBAPI ?? ""
export const invoicesEndpointUrl = window.XFX_WEBAPI_INVOICE ?? ""
export let tenantId = ""

export const XfXApi = {
  GetTenantId: () => tenantId,
  SetTenantId: (t) => tenantId = t,
  Info: new InfoApi(null, ksefixEndpointUrl),
  Info2: new Info2Api(null, ksefixEndpointUrl),
  Organization: new OrganizationApi(null, ksefixEndpointUrl),
  OrganizationODataApi: new OrganizationODataApi(null, ksefixEndpointUrl),
  Dictionary: new DictionaryApi(null, ksefixEndpointUrl),
  OrganizationSyncSetting: new OrganizationSyncSettingApi(null, ksefixEndpointUrl),
  User: new UserApi(null, ksefixEndpointUrl),
  UserODataApi: new UserODataApi(null, ksefixEndpointUrl),
  Contractor: new ContractorApi(null, ksefixEndpointUrl),
  Pipeline: new PipelineApi(null, ksefixEndpointUrl),
  ModuleApi: new ModuleApi(null, ksefixEndpointUrl),
  AccountingSystem: new AccountingSystemApi(null, ksefixEndpointUrl),
  Role: new RoleApi(null, ksefixEndpointUrl),
  DocumentTemplate: new DocumentTemplateApi(null, ksefixEndpointUrl),
  License: new LicenseApi(null, ksefixEndpointUrl),
  KsefRoleApi: new KsefRoleApi(null, ksefixEndpointUrl),
  KsefAuthApi: new KsefAuthApi(null, ksefixEndpointUrl),
  KsefCredentialsApi: new KsefCredentialsApi(null, ksefixEndpointUrl),
  ExitPath: new ExitPathApi(null, ksefixEndpointUrl),
  UserSettings: new UserSettingsApi(null, ksefixEndpointUrl),
  BatchApi: new BatchApi(null, invoicesEndpointUrl),
  InvoiceApi: new InvoicesApi(null, invoicesEndpointUrl),
  AttachmentsApi: new AttachmentsApi(null, invoicesEndpointUrl),
  TagsApi: new TagsApi(null, invoicesEndpointUrl),
  ExportApi: new ExportApi(null, invoicesEndpointUrl),
  JobsApi: new JobsApi(null, invoicesEndpointUrl),
  DictionaryInvoice: new DictionaryInvoiceApi(null, invoicesEndpointUrl),
  SettingsApi: new SettingsApi(null, ksefixEndpointUrl),
  EditorApi: new EditorApi(null, invoicesEndpointUrl),
  NotificationConfiguration: new NotificationConfigurationApi(null, ksefixEndpointUrl),
  SecurityLog: new SecurityLogApi(null, ksefixEndpointUrl),
  ConfigS3Api: new ConfigS3Api(null, invoicesEndpointUrl),
  NumberingSeries: new NumberingSeriesApi(null, ksefixEndpointUrl),
  DraftApi: new InvoiceDraftApi(null, invoicesEndpointUrl),
  ReportApi: new ReportApi(null, ksefixEndpointUrl),
  ReportOdataApi: new ReportODataApi(null, ksefixEndpointUrl),
  SelfInvoicingGrantedOdata: new SelfInvoicingGrantedOdataApi(null, ksefixEndpointUrl),
  SelfInvoicingGranted: new SelfInvoicingGrantedApi(null, ksefixEndpointUrl),
  ConfigImportApi: new ConfigImportApi(null, ksefixEndpointUrl),
  ConfigExportApi: new ConfigExportApi(null, ksefixEndpointUrl),
  AccountingSystemODataApi: new AccountingSystemODataApi(null, ksefixEndpointUrl),
  MigrationApi: new MigrationApi(null, invoicesEndpointUrl),
}

export const NotificationHubUrl = (tenantId) => {
  return ksefixEndpointUrl + `/${tenantId}/NotificationHub`
}

export const OdataEndpoints = {

  PurchaseProcessesFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/PurchaseProcessesFailed`,
  PurchaseProcessesProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/PurchaseProcessesProcessing`,
  PurchaseProcessesRepository: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/PurchaseProcessesRepository`,
  ReceptionCheckups: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionCheckups`,

  SalesProcessesFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/SalesProcessesFailed`,
  SalesProcessesProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/dispatchsaleacceptance`,
  SalesProcessesRepository: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/SalesProcessesRepository`,

  SelfInvoicingReceiving: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/SelfInvoicingReceiving`,
  SelfInvoicingSending: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/SelfInvoicingSending`,

  Module: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/Module`,
  KsefRole: (organizationId) => `${ksefixEndpointUrl}/api/${tenantId}/${organizationId}/odata/KsefRole`,
  KsefTokens: (organizationId) => `${ksefixEndpointUrl}/api/${tenantId}/${organizationId}/odata/KsefTokens`,
  KsefSessions: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/Sessions`,
  DocumentTemplates: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/DocumentTemplateOdata`,
  NumberingSeries: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/NumberingSeriesOdata`,
  Attachments: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/attachmentsodata`,
  Jobs: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/Jobs`,
  SecurityLog: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/SecurityLog`,
  Reports: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/ReportOdata`,
  SelfInvoicingGrantedOdata: (organizationId) => `${ksefixEndpointUrl}/api/${tenantId}/${organizationId}/odata/SelfInvoicingGrantedOdata`,
  Users: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/UserOData`,
  Organizations: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/OrganizationOData`,
  ConfigImport: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/ConfigImportOData`,
  AccountingSystems: () => `${ksefixEndpointUrl}/api/${tenantId}/odata/AccountingSystemOData`,
}

export const KsefixEndpoints = {
  DocumentTemplates: () => ksefixEndpointUrl + `/api/${tenantId}/DocumentTemplate`,
  DocumentTemplatesEdit: (id) => ksefixEndpointUrl + `/api/${tenantId}/DocumentTemplate/${id}`,
  DocumentTemplatesFile: () => ksefixEndpointUrl + `/api/${tenantId}/DocumentTemplate/file`,
  NipList: () => ksefixEndpointUrl + `/api/${tenantId}/NipList`,
  ConfigImport: () => ksefixEndpointUrl + `/api/${tenantId}/ConfigImport`,
  GetConfigFile: (id) => ksefixEndpointUrl + `/api/${tenantId}/ConfigImport/file?id=${id}`,
  GetReportFile: (id) => ksefixEndpointUrl + `/api/${tenantId}/ConfigImport/report?id=${id}`,
}

export const InvoicesEndpoints = {
  BaseDispatchXml: (organizationId) => `api/${tenantId}/${organizationId}/invoices/dispatch/xml`,
  DispatchXml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/dispatch/xml`,
  BaseReceptionXml: (organizationId) => `api/${tenantId}/${organizationId}/invoices/reception/xml`,
  ReceptionXml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/reception/xml`,
  BaseDispatchPdf: (organizationId) => `api/${tenantId}/${organizationId}/invoices/dispatch/pdf`,
  DispatchPdf: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/dispatch/pdf`,
  BaseReceptionPdf: (organizationId) => `api/${tenantId}/${organizationId}/invoices/reception/pdf`,
  ReceptionPdf: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/reception/pdf`,
  DispatchHtml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/dispatch/html`,
  ReceptionHtml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/reception/html`,
  Base: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices`,
  DraftImport: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/InvoiceDraft/draft`, 
  Attachments: () => `${invoicesEndpointUrl}/api/${tenantId}/attachments`,
  BulkFromTokens: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/bulkfromtokens`,

  BaseUpo: (organizationId) => `api/${tenantId}/${organizationId}/invoices/upo`,
  Upo: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/upo`,
  BaseUpoPdf: (organizationId) => `api/${tenantId}/${organizationId}/invoices/upo/pdf`,
  UpoPdf: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/upo/pdf`,

  InvoiceDraft: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/InvoiceDrafts`,
  DraftsXml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoicedraft/xml`,
  HtmlFromXml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/editor/html-from-xml`,
  PdfFromXml: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/editor/pdf-from-xml`,

  DispatchSaleProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSaleProcessing`,
  DispatchSaleAcceptance: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSaleAcceptance`,
  DispatchSaleFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSaleFailed`,
  DispatchSaleBatchToSend: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSaleBatchToSend`,
  DispatchSaleBatchSending: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSaleBatchSending`,

  DispatchSelfInvoicingProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSelfInvoicingProcessing`,
  DispatchSelfInvoicingAcceptance: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSelfInvoicingAcceptance`,
  DispatchSelfInvoicingFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSelfInvoicingFailed`,
  DispatchSelfInvoicingBatchToSend: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSelfInvoicingBatchToSend`,
  DispatchSelfInvoicingBatchSending: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchSelfInvoicingBatchSending`,

  DispatchOtherProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchOtherProcessing`,
  DispatchOtherAcceptance: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchOtherAcceptance`,
  DispatchOtherFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchOtherFailed`,
  DispatchOtherBatchToSend: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchOtherBatchToSend`,
  DispatchOtherBatchSending: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/DispatchOtherBatchSending`,

  ReceptionPurchaseProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseProcessing`,
  ReceptionPurchaseAlert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseAlert`,
  ReceptionPurchaseRevoked: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseRevoked`,

  ReceptionSaleProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSalesProcessing`,
  ReceptionSaleAlert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSalesAlert`,
  ReceptionSaleRevoked: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSalesRevoked`,

  ReceptionSelfInvoicingSale: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSelfInvoicingProcessing`,
  ReceptionSelfInvoicingAlert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSelfInvoicingAlert`,
  ReceptionSelfInvoicingRevoked: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionSelfInvoicingRevoked`,

  ReceptionRepresentationProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionRepresentationProcessing`,
  ReceptionRepresentationAlert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionRepresentationAlert`,
  ReceptionRepresentationFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionRepresentationRevoked`,

  ReceptionPurchaseP3Processing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseP3Processing`,
  ReceptionPurchaseP3Alert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseP3Alert`,
  ReceptionPurchaseP3Revoked: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionPurchaseP3Revoked`,

  ReceptionOtherProcessing: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionOtherProcessing`,
  ReceptionOtherAlert: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionOtherAlert`,
  ReceptionOtherFailed: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/ReceptionOtherRevoked`,

  RepositorySales: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/SalesRepository`,
  RepositoryPurchase: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/PurchaseRepository`,
  RepositoryOther: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/odata/OtherRepository`,

  CreateArchive: (organizationId) => `${invoicesEndpointUrl}/api/${tenantId}/${organizationId}/invoices/createarchive`,
}

const AuthHeaderKey = 'Authorization'
var AuthType = ''
var AuthToken = ''
var GetToken = () => ""
var LanguageCode = "pl"
var XfxSession = null

export const GetAuthHeader = async () => {
  const tokenData = (await GetToken());
  AuthToken = tokenData.accessToken
  return { key: AuthHeaderKey, value: `${AuthType} ${AuthToken}`, expiresOn: tokenData.expiresOn }
}

export const GetAuthHeaderForce = async (instance, account) => {
  const tokenData = (await AADGetTokenDataForce(instance, account));
  return { key: AuthHeaderKey, value: `${AuthType} ${tokenData.accessToken}`, expiresOn: tokenData.expiresOn }
}

export const GetRecentAuthHeader = () => {
  return { key: AuthHeaderKey, value: `${AuthType} ${AuthToken}` }
}

export const SetBasicAuth = (login, password) => {
  AuthType = 'Basic'
  const authToken = ' ' + btoa(login + ":" + password)
  GetToken = () => { return { accessToken: authToken }; }
}

export const SetAADAuth = (getTokenData) => {
  AuthType = 'Bearer'
  GetToken = getTokenData
  GetAuthHeader() // for first set AuthToken
}

export const SetXfxSession = guid => {
  XfxSession = guid
}

export const GetXfxSession = () => {
  return XfxSession
}

export const SetLanguageCodeForApi = (code) => {
  LanguageCode = code
}

export const GetAuthToken = () => AuthToken
export const GetAuthType = () => AuthType

export const Logout = () => {
  delete axios.defaults.headers.common[AuthHeaderKey]
  AuthToken = ''
}

axios.interceptors.request.use(async (config) => {
  const authHeader = await GetAuthHeader()
  config.headers.common[authHeader.key] = authHeader.value
  config.headers.common["Accept-Language"] = LanguageCode
  config.headers.common["Xfx-Session"] = XfxSession
  return config;
});

export const ConfigureResponseInterceptor = (onFulfilledHandler, onRejectedHandler) => {
  axios.interceptors.response.use(
    async (response) => {
      if (onFulfilledHandler) {
        onFulfilledHandler(response);
      }
      return response;
    },
    async (error) => {
      if (onRejectedHandler) {
        onRejectedHandler(error);
      }
      return Promise.reject(error);
    }
  );
};
